<template lang="pug">
div
    .st-container
      .matrix-view
        .matrix-view__header
          .matrix-view__title
            router-link(
              to="/skill-gap/matrix-list")
              arrow-back-fill
              span {{ 'pages.skill_gap.matrix_lists' | translate }}
          .matrix-view__toolbar
            v-btn(
              v-if="hasCollaboratorPermissions()"
              :loading="loading"
              @click="openEditModal")
              | {{ 'pages.skill_gap.configure_matrix' | translate }}

    matrix.mb-40(
      ref="matrix")

    v-dialog(
      :width="616"
      :height="636"
      v-model="showEditModal")
      edit-matrix-modal(
        :matrix="matrix"
        @close="showEditModal = false"
        @complete="onComplete")

</template>

<script>
import { mapActions } from 'vuex'

import Matrix from './Matrix'
import EditMatrixModal from '../matrix-list/modals/edit/EditMatrixModal'
import ArrowBackFill from '@/components/svg/ArrowBackFill.vue'

export default {
  name: 'Index',

  components: {
    Matrix,
    EditMatrixModal,
    ArrowBackFill
  },

  data: () => ({
    matrix: null,
    showEditModal: false,
    loading: false
  }),

  async mounted () {
    const id = this.$route.params.id
    this.matrix = await this.getMatrixById(id)
  },

  methods: {
    ...mapActions('insightsMatrix', [
      'getMatrixById'
    ]),

    async openEditModal () {
      const id = this.$route.params.id
      this.matrix = await this.getMatrixById(id)
      this.showEditModal = true
    },

    async onComplete () {
      this.loading = true
      await this.$refs.matrix.init()
      this.loading = false
    },

    hasCollaboratorPermissions () {
      return this.matrix ? this.matrix.collaboratorTypeId !== 'VIEW_ONLY' : false
    }
  }
}
</script>

<style lang="scss" scoped>
  .matrix-view {
    padding: 24px 0;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      color: $body-text-2;

     ::v-deep svg {
        height: 16px;
        width: 16px;
        margin-right: 8px;
      }

      a {
        color: $body-text-2;
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
      }
    }
  }
  .mb-40{
    margin-bottom: 40px;
  }
</style>
