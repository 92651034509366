<template functional>
<svg  :class="[data.class, data.staticClass]" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.6667 7.33413L5.76008 7.33413L8.18008 4.42746C8.29324 4.29131 8.34768 4.11579 8.33143 3.93951C8.31517 3.76323 8.22956 3.60062 8.09341 3.48746C7.95727 3.3743 7.78175 3.31986 7.60546 3.33611C7.42918 3.35236 7.26657 3.43798 7.15341 3.57413L3.82008 7.57413C3.79766 7.60594 3.7776 7.63937 3.76008 7.67413C3.76008 7.70746 3.76008 7.72746 3.71341 7.76079C3.6832 7.83723 3.66738 7.9186 3.66675 8.00079C3.66738 8.08299 3.6832 8.16435 3.71341 8.24079C3.71341 8.27413 3.71341 8.29413 3.76008 8.32746C3.7776 8.36222 3.79766 8.39564 3.82008 8.42746L7.15341 12.4275C7.2161 12.5027 7.29459 12.5632 7.38331 12.6047C7.47203 12.6462 7.56881 12.6676 7.66675 12.6675C7.82252 12.6678 7.97347 12.6135 8.09341 12.5141C8.16092 12.4582 8.21672 12.3894 8.25762 12.3119C8.29852 12.2343 8.32372 12.1494 8.33177 12.0621C8.33982 11.9748 8.33057 11.8867 8.30454 11.803C8.27851 11.7193 8.23622 11.6415 8.18008 11.5741L5.76008 8.66746L11.6667 8.66746C11.8436 8.66746 12.0131 8.59722 12.1382 8.4722C12.2632 8.34717 12.3334 8.1776 12.3334 8.00079C12.3334 7.82398 12.2632 7.65441 12.1382 7.52939C12.0131 7.40436 11.8436 7.33413 11.6667 7.33413Z" fill="currentColor"/>
</svg>

</template>

<script>
export default {
  name: 'ArrowBackFill'
}
</script>

<style scoped></style>
